function HardLiquor() {

    return (

        <div>

            <table className='table'>

                <tbody>
                
                    <tr className='td'>
                        <th className='articles' colspan='2'><h3>Žestoka pića</h3><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Rakije<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Dunja Racka 0.03<br /><hr /></td>
                        <td className='prices'>320 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <th className='articles'>Benišek-Veselinović:<br /><hr /></th>
                        <td className='prices'>&nbsp;<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kajsija 0.03<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kruška 0.03<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Šljiva 0.03<br /><hr /></td>
                        <td className='prices'>240 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Orahovača 0.03<br /></td>
                        <td className='prices'>240 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Kratka pića<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Gin 0.03<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Herbal Gin - Ginović 0.03<br /><hr /></td>
                        <td className='prices'>270 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Floral Gin - Ginović 0.03<br /><hr /></td>
                        <td className='prices'>270 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Vodka 0.03<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Tekila 0.03<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jameson 0.03<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jack Daniels 0.03<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Gorki list 0.03<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Vinjak 0.03<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Beli rum 0.03<br /></td>
                        <td className='prices'>170 din<br /></td>
                    </tr>

                </tbody>

            </table>

        </div>

    );

}

export default HardLiquor;
