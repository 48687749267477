import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NoPage from './noPage';
import GMainSrb from './galerija/gMainSrb';
import GLayoutSrb from './galerija/gLayoutSrb';

import '../../css/gallery.css';

import Galerija17 from './galerija/galerija17';
import Galerija18 from './galerija/galerija18';
import Galerija19 from './galerija/galerija19';
import Galerija20 from './galerija/galerija20';
import Galerija21 from './galerija/galerija21';
import Galerija22 from './galerija/galerija22';
import Galerija23 from './galerija/galerija23';
import Galerija24 from './galerija/galerija24';
import Galerija25 from './galerija/galerija25';

function Galerija() {

    return (

        <div>

            <Routes>
                <Route path='/' element={<GLayoutSrb />}>
                    <Route index element={<GMainSrb />} />
                    <Route path='galerija17' element={<Galerija17 />} />
                    <Route path='galerija18' element={<Galerija18 />} />
                    <Route path='galerija19' element={<Galerija19 />} />
                    <Route path='galerija20' element={<Galerija20 />} />
                    <Route path='galerija21' element={<Galerija21 />} />
                    <Route path='galerija22' element={<Galerija22 />} />
                    <Route path='galerija23' element={<Galerija23 />} />
                    <Route path='galerija24' element={<Galerija24 />} />
                    <Route path='galerija25' element={<Galerija25 />} />
                    <Route path='*' element={<NoPage />} />
                </Route>
            </Routes>

        </div>

    );

}

export default Galerija;
