import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NoPage from './noPage';
import GMainEng from './gallery/gMainEng';
import GLayoutEng from './gallery/gLayoutEng';

import Gallery17 from './gallery/gallery17';
import Gallery18 from './gallery/gallery18';
import Gallery19 from './gallery/gallery19';
import Gallery20 from './gallery/gallery20';
import Gallery21 from './gallery/gallery21';
import Gallery22 from './gallery/gallery22';
import Gallery23 from './gallery/gallery23';
import Gallery24 from './gallery/gallery24';
import Gallery25 from './gallery/gallery25';

function Gallery() {

    return (

        <div>

            <Routes>
                <Route path='/' element={<GLayoutEng />}>
                    <Route index element={<GMainEng />} />
                    <Route path='gallery17' element={<Gallery17 />} />
                    <Route path='gallery18' element={<Gallery18 />} />
                    <Route path='gallery19' element={<Gallery19 />} />
                    <Route path='gallery20' element={<Gallery20 />} />
                    <Route path='gallery21' element={<Gallery21 />} />
                    <Route path='gallery22' element={<Gallery22 />} />
                    <Route path='gallery23' element={<Gallery23 />} />
                    <Route path='gallery24' element={<Gallery24 />} />
                    <Route path='gallery25' element={<Gallery25 />} />
                    <Route path='*' element={<NoPage />} />
                </Route>
            </Routes>

        </div>

    );

}

export default Gallery;
