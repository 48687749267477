function Beverages() {

    return (

        <div>

            <table className='table'>

                <tbody>
                
                    <tr className='td'>
                        <th className='articles' colspan='2'><h3>Sokovi & Energetska Pića</h3><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Prirodni sokovi<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Višnja 0.2<br /><hr /></td>
                        <td className='prices'>330 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jagoda 0.2<br /><hr /></td>
                        <td className='prices'>330 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Celer & Jabuka 0.2<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Crni mix 0.2<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Malina 0.2<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kajsija 0.2<br /><hr /></td>
                        <td className='prices'>330 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Crveni mix 0.2<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jabuka 0.2<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kruška 0.2<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zova 0.5<br /><hr /></td>
                        <td className='prices'>240din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zova sa sodom 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Grožđe 0.25<br /><hr /></td>
                        <td className='prices'>330 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Cvekla 0.25<br /></td>
                        <td className='prices'>330 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Ceđeni sokovi<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Limunada 0.5<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Đumbirlada 0.5<br /><hr /></td>
                        <td className='prices'>280 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ceđena pomorandža 0.3<br /><hr /></td>
                        <td className='prices'>380 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ceđeni mix 0.3<br /></td>
                        <td className='prices'>420 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Ledeni čajevi<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Lavanda, nana i limun 0.3<br /><hr /></td>
                        <td className='prices'>320 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Šipurak, nana i limun 0.3<br /><hr /></td>
                        <td className='prices'>320 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Brusnica, ribizla i nana 0.3<br /></td>
                        <td className='prices'>320 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Kombuha<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Kombuha limenka 0.3<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kombuha točena 0.3<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kombuha točena 0.5<br /></td>
                        <td className='prices'>420 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Gazirani & negazirani sokovi<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Coca Cola 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Coca Cola Zero 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Fanta 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Sprite 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Schweppes Bitter Lemon 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Schweppes Tonic 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Fuzetea breskva 0.5<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Cedevita narandža 0.3<br /></td>
                        <td className='prices'>220 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Energetska pića<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Guarana 0.33<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Guarana 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Red Bull 0.33<br /></td>
                        <td className='prices'>360 din<br /></td>
                    </tr>

                </tbody>
                
                {/*<tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'>Sokovi & Energetska Pića<br /><hr /></th>
                    </tr>

                    <br />

                    <tr className='td'>
                        <th className='articles'>Kafe<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Kafe<br /><hr /></td>
                        <td className='prices'>cene<br /><hr /></td>
                    </tr>

                </tbody>*/}

            </table>

            

        </div>

    );

}

export default Beverages;
