function Food() {

    return (

        <div>

            <table className='table'>

                <tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'>Nešto za prezalogajiti<br /><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Slano<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Tortilja sa piletinom<br /><hr /></td>
                        <td className='prices'>480 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Smoki 150g<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Chips 95g<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kokice 50g<br /><hr /></td>
                        <td className='prices'>160 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kikiriki 80g<br /></td>
                        <td className='prices'>200 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Slatko<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Trileće kolač<br /><hr /></td>
                        <td className='prices'>380 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Proteinska ovsena kaša<br /><hr /></td>
                        <td className='prices'>520 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ovsena kaša<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Plazma 75g<br /><hr /></td>
                        <td className='prices'>160 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Sladoled štapić<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Sladoled kornet<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Madžun - grožđani med<br /></td>
                        <td className='prices'>900 din<br /></td>
                    </tr>

                </tbody>

            </table>

        </div>

    );

}

export default Food;
