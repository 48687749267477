import Sneg from '../../../images/gallery/2025/sneg.webp';
import Bina from '../../../images/gallery/2025/bina.webp';
import Inje from '../../../images/gallery/2025/inje.webp';
import Duga from '../../../images/gallery/2025/duga.webp';
import OsmiMart from '../../../images/gallery/2025/osmimart.webp';
import BadnjiDan from '../../../images/gallery/2025/badnjidan.webp';
import SuncaniDan from '../../../images/gallery/2025/suncanidan.webp';
import SrecanBozic from '../../../images/gallery/2025/srecanbozic.webp';
import VisecaMreza from '../../../images/gallery/2025/visecamreza.webp';
import LogorskaVatra from '../../../images/gallery/2025/logorskavatra.webp';
import SnezniPuteljak from '../../../images/gallery/2025/snezniputeljak.webp';
import StazamaOslobodjenja from '../../../images/gallery/2025/stazamaoslobodjenja.webp';

/*import  from '../../../images/gallery/2025/.jpg';*/

function Gallery25() {

    return (

        <div className='middle'>

            <div className='row'>
                        
                <div className='column'>
                    <img src={SnezniPuteljak} alt='' />
                    <img src={BadnjiDan} alt='' />
                    <img src={OsmiMart} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>
                        
                <div className='column'>
                    <img src={Sneg} alt='' />
                    <img src={StazamaOslobodjenja} alt='' />
                    <img src={Duga} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>
                        
                <div className='column'>
                    <img src={Inje} alt='' />
                    <img src={LogorskaVatra} alt='' />
                    <img src={VisecaMreza} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>
                        
                <div className='column'>
                    <img src={SrecanBozic} alt='' />
                    <img src={SuncaniDan} alt='' />
                    <img src={Bina} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>

            </div>

        </div>

    );

}

export default Gallery25;
