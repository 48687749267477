function Drinks() {

    return (

        <div>

            <table className='table'>

                <tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'><h3>Topli napici</h3><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Kafe<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Domaća kafa<br /><hr /></td>
                        <td className='prices'>170 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso mleko<br /><hr /></td>
                        <td className='prices'>210 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Cappuccino<br /><hr /></td>
                        <td className='prices'>220 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Latte<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ness<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ness 3 u 1<br /><hr /></td>
                        <td className='prices'>180 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso biljno mleko<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Cappuccino biljno mleko<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Latte biljno mleko<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Ness biljno mleko<br /></td>
                        <td className='prices'>300 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Bezkofeinska kafa<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso<br /><hr /></td>
                        <td className='prices'>270 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso mleko<br /><hr /></td>
                        <td className='prices'>290 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Espresso biljno mleko<br /></td>
                        <td className='prices'>320 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Čajevi	<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Nana<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Kamilica<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Voćni<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Crni<br /><hr /></td>
                        <td className='prices'>200 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zeleni<br /></td>
                        <td className='prices'>200 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Topla čokolada<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Crna topla čokolada<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Crna topla čokolada biljno mleko<br /></td>
                        <td className='prices'>300 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Dodaci<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Med<br /><hr /></td>
                        <td className='prices'>30 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Šlag<br /></td>
                        <td className='prices'>50 din<br /></td>
                    </tr>

                </tbody>

                {/*<tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'>Topli napici<br /><hr /></th>
                    </tr>

                    <br />

                    <tr className='td'>
                        <th className='articles'>Kafe<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Kafe<br /><hr /></td>
                        <td className='prices'>Cene<br /><hr /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <br />

                    <tr className='td'>
                        <th className='articles'>Kafe<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Kafe<br /><hr /></td>
                        <td className='prices'>Cene<br /><hr /></td>
                    </tr>

                </tbody>*/}

            </table>

        </div>

    );

}

export default Drinks;
