function Akcije() {

    return (

        <div>

            <table className='table'>

                {/*<tbody>
                    
                    <tr className='articles'>

                        <th className='td'>
                            Akcije
                        <hr />
                        </th>

                        <th className='td'>
                            Cene
                            <hr />
                        </th>

                    </tr>

                    <tr>

                        <td className='td articles center'>
                            <center>Svake Srede:<br />
                            Kombuha + bilo koja kafa gratis<br /></center>
                            <hr />
                            Svakog Četvrtka:<br />
                            kolač Trileće + bilo koja kafa gratis<br />
                        </td>

                        <td className='td prices center'>
                            360 din<br />
                            <hr />
                            380 din<br />
                        </td>

                    </tr>

                </tbody>*/}

                <tbody>

                    <tr className='td' colspan='2'>
                        <th className='articles'>Akcije<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    {/*<br />*/}

                    <tr className='td'>
                        <td className='articles center'>Svake Srede:<br />
                            Kombuha + bilo koja kafa gratis<br /></td>
                        <td className='prices center'>360 din<br /></td>
                    </tr>

                    <tr>
                        <td colspan='2'><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles center'>Svakog Četvrtka:<br /> Kolač Trileće + bilo koja kafa gratis<br /></td>
                        <td className='prices center'>380 din<br /></td>
                    </tr>

                </tbody>
            
            </table>

        </div>

    );

}

export default Akcije;
