function Wines() {

    return (

        <div>

            <table className='table'>

                <tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'>Vina & Sajderi<br /><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Vina<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Fruškać belo vino 0.15<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Fruškać crno vino 0.15<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Fruškać roze vino 0.15<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Proseko 0.15 <br /><hr /></td>
                        <td className='prices'>340 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Beli špricer 0.3<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Roze špricer 0.3<br /></td>
                        <td className='prices'>300 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Sajderi<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Somersby Jabuka 0.33<br /><hr /></td>
                        <td className='prices'>340 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Somersby Kruška 0.33<br /><hr /></td>
                        <td className='prices'>340 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Somersby Mango 0.33<br /></td>
                        <td className='prices'>340 din<br /></td>
                    </tr>

                </tbody>

            </table>

        </div>

    );

}

export default Wines;
