/*import Menu from '../images/misc/menu.png';*/
import Drinks from './menu/drinks';
import Water from './menu/water';
import Beverages from './menu/beverages';
import Beer from './menu/beer';
import HardLiquor from './menu/hardLiquor';
import Wines from './menu/wines';
import Cocktails from './menu/cocktails';
import Food from './menu/food';
import Akcije from './menu/akcije';

function PriceList() {

    return (

        <div>

                <table className='table'>

                    <tr>

                        <td className=''>
                            <Drinks />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Water />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Beverages />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Beer />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <HardLiquor />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Wines />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Cocktails />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Food />
                        </td>

                    </tr>

                    <br />

                    <tr>

                        <td className=''>
                            <Akcije />
                        </td>

                    </tr>

                </table>

        </div>

    );

}

export default PriceList;
