function Cocktails() {

    return (

        <div>

            <table className='table'>

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Kokteli<br /><hr /></th>
                        <th className='articles'>Cene<br /><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    {/*<tr className='td'>
                        <th className='articles' colspan='2'>Kokteli<br /><hr /></th>
                    </tr>*/}

                    <tr>
                        <td className='articles'>Šumska dunja 0.5<br /><hr /></td>
                        <td className='prices'>600 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Aperol špric 0.5<br /><hr /></td>
                        <td className='prices'>600 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Đus vodka 0.2<br /><hr /></td>
                        <td className='prices'>300 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Gin tonic 0.3<br /><hr /></td>
                        <td className='prices'>320 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Herbal gin tonic 0.3<br /><hr /></td>
                        <td className='prices'>420 din<br /><hr /></td>
                    </tr>
                    
                    <tr>
                        <td className='articles'>Floral gin tonic 0.3<br /></td>
                        <td className='prices'>420 din<br /></td>
                    </tr>

                </tbody>
            
            </table>

        </div>

    );

}

export default Cocktails;
