function Water() {

    return (

        <div>

            <table className='table'>

                <tbody>

                    <tr className='td'>
                        <th className='articles' colspan='2'><h3>Voda</h3><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Negazirana voda<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Rosa 0.5<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Voda Voda 0.5<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Rosa 1.5<br /></td>
                        <td className='prices'>220 din<br /></td>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Gazirana voda<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Knjaz Miloš 0.5<br /><hr /></td>
                        <td className='prices'>190 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Čaša sode vode 0.3<br /><hr /></td>
                        <td className='prices'>60 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Čaša sode vode 0.5<br /><hr /></td>
                        <td className='prices'>80 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Knjaz Miloš 1.75<br /></td>
                        <td className='prices'>220 din<br /></td>
                    </tr>

                </tbody>

            </table>

        </div>

    );

}

export default Water;
