import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './layout';
import Main from './main';
import Galerija from './srb/galerija';
import ONama from './srb/oNama';
import PriceList from './priceList';
import Gallery from './eng/gallery';
import AboutUs from './eng/aboutUs';
import NoPage from './noPage';

function Menu() {

    return (

        <div className='menu'>

            <BrowserRouter>

                <Routes>

                    <Route path='/' element={<Layout />} >

                        <Route index element={<Main />} />
                        <Route path='galerija/*' element={<Galerija />} />
                        <Route path='oNama' element={<ONama />} />
                        <Route path='priceList' element={<PriceList />} />
                        <Route path='gallery/*' element={<Gallery />} />
                        <Route path='aboutUs' element={<AboutUs />} />
                        <Route path='*' element={<NoPage />} />

                    </Route>

                </Routes>
                
            </BrowserRouter>

        </div>

    );

}

export default Menu;
