function Beer() {

    return (

        <div>

            <table className='table'>

                <tbody>
                
                    <tr className='td'>
                        <th className='articles' colspan='2'><h3>Piva</h3><hr /></th>
                    </tr>

                </tbody>

                <br />

                <tbody>

                    <tr className='td'>
                        <th className='articles'>Industrijska piva<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Jelen 0.5<br /><hr /></td>
                        <td className='prices'>240 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jelen 0.33<br /><hr /></td>
                        <td className='prices'>250 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jelen Cool 0.5<br /><hr /></td>
                        <td className='prices'>240 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jelen Grejp 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Jelen limun & nana 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Lav premium 0.5<br /><hr /></td>
                        <td className='prices'>240 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zaječarsko 0.5<br /><hr /></td>
                        <td className='prices'>240 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Nikšićko Svetlo 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Nikšićko Tamno 0.5<br /><hr /></td>
                        <td className='prices'>260 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Heineken 0.5<br /><hr /></td>
                        <td className='prices'>320 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Heineken 0.25<br /></td>
                        <td className='prices'>340 din<br /></td>
                    </tr>

                </tbody>
                
                <br />

                <tbody>

                    <br />

                    <tr className='td'>
                        <th className='articles'>Zanatska piva (* za poneti)<br /><hr /></th>
                        <th className='prices'>Cene<br /><hr /></th>
                    </tr>

                    <tr>
                        <td className='articles'>Točeno Šumsko pivo 0.5<br /><hr /></td>
                        <td className='prices'>420 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Točeno Šumsko pivo 0.3<br /><hr /></td>
                        <td className='prices'>380 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Šumsko pivo 0.3<br /><hr /></td>
                        <td className='prices'>380 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zbir specijal točeno 0.5<br /><hr /></td>
                        <td className='prices'>460 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Zbir specijal točeno 0.3<br /><hr /></td>
                        <td className='prices'>420 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>Wartburg 0.5<br /><hr /></td>
                        <td className='prices'>360 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>*Točeno Šumsko pivo 1l<br /><hr /></td>
                        <td className='prices'>760 din<br /><hr /></td>
                    </tr>

                    <tr>
                        <td className='articles'>*Zbir specijal točeno 1l<br /></td>
                        <td className='prices'>860 din<br /></td>
                    </tr>

                </tbody>

            </table>

        </div>

    );

}

export default Beer;
