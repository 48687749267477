import JogaSetnja from '../../../images/gallery/2024/jogasetnja.jpg';
import SuperGroove from '../../../images/gallery/2024/supergroove.jpg';
import AstroSetnja from '../../../images/gallery/2024/astrosetnja.jpg';
import Rodjendan2024 from '../../../images/gallery/2024/rodjendan2024.jpg';

import Noc from '../../../images/gallery/2024/noc.jpg';
import Noc1 from '../../../images/gallery/2024/noc1.jpg';
import DanZena from '../../../images/gallery/2024/8mart.jpg';
import DanZena1 from '../../../images/gallery/2024/8mart1.jpg';
import Sneg from '../../../images/gallery/2024/sneg.jpg';
import Sneg1 from '../../../images/gallery/2024/sneg1.jpg';
import Sneg2 from '../../../images/gallery/2024/sneg2.jpg';
import Sneg3 from '../../../images/gallery/2024/sneg3.jpg';
import Sneg4 from '../../../images/gallery/2024/sneg4.jpg';
import Sneg5 from '../../../images/gallery/2024/sneg5.jpg';
import Sneg6 from '../../../images/gallery/2024/sneg6.jpg';

import Ribice from '../../../images/gallery/2024/ribice.jpg';
import CiscenjeSume from '../../../images/gallery/2024/ciscenjesume.jpg';
import Sunce from '../../../images/gallery/2024/sunce.jpg';
import Zid from '../../../images/gallery/2024/zid.jpg';
import Zid2 from '../../../images/gallery/2024/zid2.jpg';
import Ognjiste from '../../../images/gallery/2024/ognjiste.jpg';
import Zelenilo from '../../../images/gallery/2024/zelenilo.jpg';
import Maske from '../../../images/gallery/2024/maske.jpg';
import Sedalica from '../../../images/gallery/2024/sedalica.jpg';
import Bioskop from '../../../images/gallery/2024/bioskop.jpg';
import Bioskop1 from '../../../images/gallery/2024/bioskop1.jpg';
import Bundeve from '../../../images/gallery/2024/bundeve.jpg';
import Kisa from '../../../images/gallery/2024/kisa.jpg';
import Knjige from '../../../images/gallery/2024/knjige.jpg';
import Lisce from '../../../images/gallery/2024/lisce.jpg';
import Jezero1 from '../../../images/gallery/2024/jezero1.jpg';
import NocVestica2 from '../../../images/gallery/2024/nocvestica2.jpg';
import NoviProstor from '../../../images/gallery/2024/noviprostor.jpg';
import NoviProstor3 from '../../../images/gallery/2024/noviprostor3.jpg';
import NoviProstor7 from '../../../images/gallery/2024/noviprostor7.jpg';
import NoviProstor10 from '../../../images/gallery/2024/noviprostor10.jpg';

/*import  from '../../../images/gallery/2024/.jpg';*/

function Galerija24() {

    return (

        <div className='middle'>
            
            {/*<img src={Construction} className='construction' alt='Under Construction' />*/}

            <div className='row'>

                <div className='column'>
                    <img src={Sneg} alt='' />
                    <img src={DanZena} alt='' />
                    <img src={Noc} alt='' />
                    <img src={CiscenjeSume} alt='' />
                    <img src={Zid} alt='' />
                    <img src={Bundeve} alt='' />
                    <img src={Knjige} alt='' />
                    <img src={NoviProstor} alt='' />
                    <img src={Jezero1} alt='' />
                </div>

                <div className='column'>
                    <img src={Sneg1} alt='' />
                    <img src={DanZena1} alt='' />
                    <img src={Noc1} alt='' />
                    <img src={Sunce} alt='' />
                    <img src={Sedalica} alt='' />
                    <img src={Bioskop1} alt='' />
                    <img src={Ribice} alt='' />
                    <img src={NoviProstor3} alt='' />
                    <img src={Sneg4} alt='' />
                </div>

                <div className='column'>
                    <img src={Sneg2} alt='' />
                    <img src={SuperGroove} alt='' />
                    <img src={AstroSetnja} alt='' />
                    <img src={Ognjiste} alt='' />
                    <img src={Zid2} alt='' />
                    <img src={Kisa} alt='' />
                    <img src={Lisce} alt='' />
                    <img src={NoviProstor7} alt='' />
                    <img src={Sneg5} alt='' />
                </div>

                <div className='column'>
                    <img src={Sneg3} alt='' />
                    <img src={JogaSetnja} alt='' />
                    <img src={Rodjendan2024} alt='' />
                    <img src={Zelenilo} alt='' />
                    <img src={Bioskop} alt='' />
                    <img src={Maske} alt='' />
                    <img src={NocVestica2} alt='' />
                    <img src={NoviProstor10} alt='' />
                    <img src={Sneg6} alt='' />
                </div>

            </div>

        </div>

    );

}

export default Galerija24;
